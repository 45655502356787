import dynamic from 'next/dynamic';

// HOCs
import WithRole, { rolesEnum } from '@HOCs/WithRole';

const EpnPage = dynamic(import('@pages/offers/[url]/epn'));
const EpncpaPage = dynamic(import('@pages/offers/[url]/epncpa'));

export default WithRole(
    { epn: EpnPage, epncpa: EpncpaPage }[process.env.NEXT_PUBLIC_PROJECT_NAME],
    [rolesEnum.guest],
);
